import { setClosureTime, setIsCaseActive } from '../slices/questionnaire';
import { AppThunk } from '../store';
import saveData from '@/api/utils/saveData';
import SaveDataTypes from '@/api/types/saveDataTypes';

const updateCaseClosureTime =
  (closureTime: string | null, isActive: boolean): AppThunk =>
  (dispatch) => {
    dispatch(setClosureTime(closureTime));
    dispatch(setIsCaseActive(isActive));
    saveData({
      type: SaveDataTypes.Case,
    });
  };

export default updateCaseClosureTime;
