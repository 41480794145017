import { styled } from '@mui/material';
import { TextField } from '@s-rm/react-ui-lib';

export const DescriptionWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;

  h4 {
    margin-top: ${({ theme }) => theme.spacing(1)};
    text-transform: uppercase;
  }
`;

export const TextFieldWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 100px);
`;

export const ScrollableTextField = styled(TextField)`
  textarea {
    overflow-y: auto !important;
  }
`;

export const DiagnoseButtonWrapper = styled('div')`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Notice = styled('div')`
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  svg {
    height: 1rem;
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

export const RichTextEditorWrapper = styled('div')<{
  isPopoutWindowOpen: boolean;
}>`
  height: 100%;

  #case-description-input {
    height: calc(100% - 100px); // 100px is height of quill's trailing space

    & > .ql-toolbar {
      background-color: white;
      border-radius: 4px 4px 0px 0px;
    }
    & > .ql-container {
      height: 100%;
      font-size: 16px;
      border-radius: 0px 0px 4px 4px;
      background-color: white;
    }
  }

  position: ${({ isPopoutWindowOpen }) =>
    isPopoutWindowOpen ? 'relative' : 'inherit'};

  ${({ isPopoutWindowOpen }) =>
    isPopoutWindowOpen &&
    `
    &::before {
      content: "";
      background-color: rgba(228, 228, 228, 0.85);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  `}
`;

export const DescriptionHeaderWrapper = styled('div')`
  display: inline-flex;
  justify-content: space-between;
`;
