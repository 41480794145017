let windowAPIReferenceObject: Window | null = null;

export const windowManager = (
  url: string,
  name?: string,
  features?: string
) => {
  try {
    if (windowAPIReferenceObject && !windowAPIReferenceObject.closed) {
      windowAPIReferenceObject?.close();
      windowAPIReferenceObject = null;
    } else {
      windowAPIReferenceObject = window.open(url, name, features);
    }
  } catch (error) {
    console.log(`An error occurred opening or closing the popup: ${error}`);
  }
};
