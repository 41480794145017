export const slotProps = {
  textField: {
    inputProps: {
      'data-testid': 'intake-time-picker',
    },
  },
  openPickerIcon: {
    'data-testid': 'open-intake-time-picker-icon',
  },
};
