import client from '@/api/client';
// @ts-ignore
import { GetRecommendedIndicators } from '@/api/gql/queries.gql';
import getSuggestedIndicatorsTransform from '../transforms/getSuggestedIndicatorsTransform';

export const getSuggestedIndicators = async ({
  indicatorIds,
  caseId,
}: {
  indicatorIds: string[];
  caseId?: string;
}): Promise<{ data: string[]; error?: any }> => {
  const { data, error } = await client.query({
    query: GetRecommendedIndicators,
    variables: {
      indicatorIds,
      caseId,
    },
  });

  if (error) {
    return { data: [], error };
  }

  return { data: getSuggestedIndicatorsTransform(data), error };
};

export default getSuggestedIndicators;
