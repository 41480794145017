import {
  setSnackbarNotification,
  setFetchingSuggestedIndicatorsStatus,
} from '../slices/ui';
import { AppThunk } from '../store';
import getSuggestedIndicators from '@/api/queries/getSuggestedIndicators';
import { SnackbarTypes } from '../types/ui';
import {
  addSelectedIndicators,
  setSuggestedIndicators,
} from '../slices/indicators';
import { addSuggestedCaseTypes } from '../slices/caseTypes';
import getSugestedCaseTypeFromIndicators from '../utils/getSuggestedCaseTypeFromIndicators';
import { IndicatorId } from '../types/basicTypes';
import { setDescriptionAtTimeOfSuggestIndicators } from '../slices/questionnaire';
import saveData from '@/api/utils/saveData';
import SaveDataTypes from '@/api/types/saveDataTypes';

const getSuggestedIndicatorsThunk =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const indicatorsIds = getState().indicators.ids;
      const description = getState().questionnaire.description;
      const projectId = getState().questionnaire.projectId;
      const indicatorCaseTypesMap = getState().indicators.indicatorCaseTypesMap;
      const dismissedIndicators = getState().indicators.dismissed;

      dispatch(setFetchingSuggestedIndicatorsStatus({ isLoading: true }));

      dispatch(
        setSnackbarNotification({
          message: 'Fetching indicators',
          type: SnackbarTypes.Info,
        })
      );

      const { data: suggestedIndicators, error } = await getSuggestedIndicators(
        {
          indicatorIds: indicatorsIds,
          caseId: projectId,
        }
      );

      if (error) {
        dispatch(
          setSnackbarNotification({
            message: 'Failed to fetch indicators',
            type: SnackbarTypes.Error,
            disableAutoHide: true,
          })
        );
        return;
      }

      // Set this now so we can later determine if the user has changed the description
      // which will cause the suggested indicators to be out of date.
      dispatch(setDescriptionAtTimeOfSuggestIndicators(description));

      const suggestedCaseTypes = getSugestedCaseTypeFromIndicators({
        indicators: suggestedIndicators,
        indicatorCaseTypesMap,
      });

      // Construct a new array of selected indicators to be added, derived from
      // the suggested ones IF they are not dismissed.
      const selectedIndicators = suggestedIndicators.filter(
        (indicatorId: IndicatorId) => !dismissedIndicators.includes(indicatorId)
      );

      // This process will ensure that any case types related to suggested
      // indicators are displayed as suggested but not selected.
      // Any suggested indicators will be displayed as selected unless
      // they are dismissed.
      dispatch(setSuggestedIndicators(suggestedIndicators));
      dispatch(addSuggestedCaseTypes(suggestedCaseTypes));
      dispatch(addSelectedIndicators(selectedIndicators));

      // Save the data now that selected indicators have been updated.
      saveData({ type: SaveDataTypes.Case });

      dispatch(
        setSnackbarNotification({
          message: 'Indicators applied',
          type: SnackbarTypes.Success,
        })
      );
    } finally {
      dispatch(setFetchingSuggestedIndicatorsStatus({ isLoading: false }));
    }
  };

export default getSuggestedIndicatorsThunk;
