import dynamic from 'next/dynamic';
import CircularProgress from '@mui/material/CircularProgress';
import {
  defaultQuillFormats,
  defaultQuillModules,
  RichTextEditor as RichTextEditorWrapper,
} from '@s-rm/react-ui-lib';
import 'react-quill/dist/quill.snow.css';

interface RichTextEditorProps {
  id?: string;
  value: string;
  onChange: (content: string) => void;
}

// Prevents the module loading and crashing during SSR since the DOM is not available, load on client side instead.
export const QuillNoSSRWrapper = dynamic(() => import('react-quill'), {
  ssr: false,
  loading: () => <CircularProgress />,
});

const RichTextEditor = ({ id, value, onChange }: RichTextEditorProps) => (
  <RichTextEditorWrapper
    value={value}
    onChange={onChange}
    id={id}
    editorComponent={QuillNoSSRWrapper}
    editorComponentProps={{
      theme: 'snow',
      modules: defaultQuillModules,
      formats: defaultQuillFormats,
    }}
  />
);

export default RichTextEditor;
