import { useEffect } from 'react';
import { WindowMessageEventTypes } from '@/store/types/windowMessageEventTypes';
import { setDescriptionPopoutWindowStatus } from '@/store/slices/ui';
import { Dispatch } from '@reduxjs/toolkit';

export const useListenForWindowEventMessages = (
  handleChange: (payload?: any) => void,
  dispatch: Dispatch
) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === WindowMessageEventTypes.POST_MESSAGE) {
        handleChange(event.data.payload);
      }

      if (event.data.type === WindowMessageEventTypes.CLOSING_POPOUT) {
        dispatch(setDescriptionPopoutWindowStatus({ isOpen: false }));
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleChange, dispatch]);
};
