import { setQuestionnaireStartedAt } from '../slices/questionnaire';
import { AppThunk } from '../store';
import saveData from '@/api/utils/saveData';
import SaveDataTypes from '@/api/types/saveDataTypes';

const updateQuestionnaireTime =
  (intakeTime: string | null): AppThunk =>
  (dispatch) => {
    dispatch(setQuestionnaireStartedAt(intakeTime));
    saveData({
      type: SaveDataTypes.Case,
    });
  };

export default updateQuestionnaireTime;
