export const slotProps = {
  textField: {
    inputProps: {
      'data-testid': 'closure-time-picker',
    },
  },
  openPickerIcon: {
    'data-testid': 'open-closure-time-picker-icon',
  },
};
